<template>
  <div class="d-flex container justify-content-center my-5" v-if="isMounted">{{ $t('加載中') }}...</div>
</template>
<script>
import { lanToISO639, ISO639ToLan } from '@/utils'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {},
  mixins: [Lifecycle],
  data() {
    return {
      content: null,
      isMounted: false
    }
  },
  computed: {
    ...mapState('common', ['page', 'cdn_link']),
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  watch: {},
  methods: {
    langName(name) {
      return name[lanToISO639(this.$route.params.lang)]
    },
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { id } = this.$route.params
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_LINK', {
            id
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    async hanldeInit() {
      if (await this.cdn_link) {
        if (this.isMounted) {
          if (this.cdn_link != '404') {
            window.location.href = this.cdn_link
            // var link = document.createElement('a')
            // link.target = '_blank'
            // link.href = this.cdn_link
            // link.click()
            // window.open(this.cdn_link, 'about:blank')
          } else {
            this.$router.push({ name: '404' })
          }
        }
      }
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  mounted() {
    this.isMounted = true
    this.fetchData()
  }
}
</script>
